import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logosite.svg";
import TopHeader from "../../components/front/TopHeader";
import CartPopUp from "../../components/front/CartPopUp";
import { useFrontDataContext } from "../../context/FrontContextProvider";
import { authCustomer, imgBaseURL } from "../../utility/Utility";

const Header = () => {

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) { // You can adjust the scroll threshold
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const {
    categories,
    wishlistData,
    customerDetails,
    cartData,
    webAttr,
    getFuxedReviewList,
  } = useFrontDataContext();
  const navigate = useNavigate();
  const [isOpen, setDropdownOpen] = useState(false);
  const [headerSearchVal, setHeaderSearchVal] = useState();

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!event.target.closest(".dropdown") && isOpen) {
        setDropdownOpen(false);
      }
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [isOpen]);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    // setIsOpen(false);
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef(null);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
    setHeaderSearchVal();
  };

  useEffect(() => {
    getFuxedReviewList();
    const handleOutsideClick = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const redirectPage = (tab) => {
    // const data = { tab: tab };
    navigate(`/account/${tab}`);
  };

  const handleLogout = async () => {
    navigate("/");
    localStorage.clear();
    // const res = await axiosInstance.post('/logout')
    // if(res?.data.status){
    // }
  };

  const handleMobileRedirect = (page) => {
    navigate(page);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate("/shop/all", { state: { searchVal: headerSearchVal } });
  };

  return (
    <>
      <CartPopUp />
      <TopHeader></TopHeader>
      <div className={`mbile-heaader d-md-none d-block ${isScrolled ? 'scrolled' : ''}`}>
        <div className="container">
          <div className="mobile-menu-inner">
            <div className="moble-logo">
              <Link onClick={handleLinkClick} to="/">
                {" "}
                <img src={imgBaseURL() + webAttr?.logo} alt="" />
              </Link>
            </div>
            <div className="mboiel-right">
              <ul>
                {authCustomer()?.id ? (
                  <li className="dropdown">
                    <span onClick={toggleDropdown}>
                      <i className="fa-regular fa-user"></i>
                    </span>
                    {isOpen && (
                      <div className="dropdown-menu dropdown-menu-end show">
                        <h6 className="dropdown-header">
                          Welcome {authCustomer()?.name?.split(" ")[0]}
                        </h6>
                        <Link className="dropdown-item" to={"/account/orders"}>
                          <i className="fa fa-cart-plus me-1"></i> My Orders
                        </Link>
                        <Link className="dropdown-item" to={"#"}>
                          <i className="fa fa-truck me-1"></i> Track Orders
                        </Link>
                        <Link
                          className="dropdown-item"
                          to={`/account/account-info`}
                        >
                          <i className="fa fa-user me-1"></i> My Account
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link
                          to={"/"}
                          onClick={() => handleLogout()}
                          className="dropdown-item"
                        >
                          <i class="fa-solid fa-right-from-bracket"></i> Logout
                        </Link>
                      </div>
                    )}
                  </li>
                ) : (
                  <li>
                    {" "}
                    <Link to="/login">
                      <i class="fa-regular fa-user"></i>Login
                    </Link>{" "}
                  </li>
                )}



                {authCustomer()?.id && (
                  <li style={{ position: "relative" }}>
                    <button
                      className="text-dark planBtn p-0"
                      onClick={() => redirectPage("my-loyalty-points")}

                    >
                      <svg
                        className="me-1"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="20"
                        height="20"
                        x="0"
                        y="0"
                        viewBox="0 0 507.246 507.246"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            d="M457.262 89.821c-2.734-35.285-32.298-63.165-68.271-63.165H68.5c-37.771 0-68.5 30.729-68.5 68.5V412.09c0 37.771 30.729 68.5 68.5 68.5h370.247c37.771 0 68.5-30.729 68.5-68.5V155.757c-.001-31.354-21.184-57.836-49.985-65.936zM68.5 58.656h320.492c17.414 0 32.008 12.261 35.629 28.602H68.5c-13.411 0-25.924 3.889-36.5 10.577v-2.679c0-20.126 16.374-36.5 36.5-36.5zM438.746 448.59H68.5c-20.126 0-36.5-16.374-36.5-36.5V155.757c0-20.126 16.374-36.5 36.5-36.5h370.247c20.126 0 36.5 16.374 36.5 36.5v55.838H373.221c-40.43 0-73.322 32.893-73.322 73.323s32.893 73.323 73.322 73.323h102.025v53.849c0 20.126-16.374 36.5-36.5 36.5zm36.5-122.349H373.221c-22.785 0-41.322-18.537-41.322-41.323s18.537-41.323 41.322-41.323h102.025z"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                            className=""
                          />
                          <circle
                            cx="379.16"
                            cy="286.132"
                            r="16.658"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                            className=""
                          />
                        </g>
                      </svg>
                      <span> {customerDetails?.loyalty}</span>
                    </button>
                  </li>
                )}

                {authCustomer()?.id && (
                  <li
                    className="wish_phone_btn"
                    style={{ position: "relative" }}
                    onClick={() => redirectPage("wishlist")}

                  >
                    <button
                      className="border-0 p-0 text-dark"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="512"
                        height="512"
                        x="0"
                        y="0"
                        viewBox="0 0 100 100"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                        className=""
                      >
                        <g>
                          <path
                            d="M50 91a10.183 10.183 0 0 1-7.242-2.999V88L9.629 54.871C4.71 49.952 2 43.412 2 36.454v-.407c0-6.958 2.71-13.499 7.629-18.417S21.09 10 28.045 10h.41c6.955 0 13.497 2.71 18.416 7.629L50 20.758l3.129-3.129C58.048 12.71 64.59 10 71.545 10h.41c6.955 0 13.497 2.71 18.416 7.629S98 29.088 98 36.046v.407c0 6.958-2.71 13.499-7.629 18.417L57.242 88A10.18 10.18 0 0 1 50 91zm-3-7.242c1.608 1.605 4.395 1.601 6-.001L86.129 50.63A19.925 19.925 0 0 0 92 36.454v-.407c0-5.355-2.086-10.389-5.871-14.175S77.308 16 71.955 16h-.41a19.91 19.91 0 0 0-14.174 5.871l-5.25 5.25a2.998 2.998 0 0 1-4.242 0l-5.25-5.25A19.91 19.91 0 0 0 28.455 16h-.41c-5.353 0-10.389 2.084-14.174 5.871S8 30.691 8 36.046v.407a19.919 19.919 0 0 0 5.871 14.175z"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                          ></path>
                        </g>
                      </svg>
                      <span>{wishlistData?.length}</span>
                    </button>
                  </li>
                )}

                {authCustomer()?.id && (
                  <li className="cart_li">
                    <Link
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="512"
                        height="512"
                        x="0"
                        y="0"
                        viewBox="0 0 48 48"
                        xmlSpace="preserve"
                        fillRule="evenodd"
                        className=""
                      >
                        <g>
                          <path
                            d="M14.519 13.5h-2.657a3.5 3.5 0 0 0-3.491 3.251l-1.714 24a3.5 3.5 0 0 0 3.491 3.749h27.704a3.502 3.502 0 0 0 3.491-3.749l-1.714-24a3.5 3.5 0 0 0-3.491-3.251H33.5V13A9.5 9.5 0 0 0 24 3.5c-5.055 0-9.727 4.026-9.5 9.5l.019.5zm18.981 3V24a1.5 1.5 0 0 1-3 0v-7.5h-13V24a1.5 1.5 0 0 1-3 0s.13-3.505.087-7.5h-2.725a.5.5 0 0 0-.498.464l-1.715 24a.5.5 0 0 0 .499.536h27.704a.502.502 0 0 0 .499-.536l-1.715-24a.5.5 0 0 0-.498-.464zm-3-3V13a6.5 6.5 0 1 0-13 0v.5z"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                          ></path>
                        </g>
                      </svg>
                      <span>{cartData?.length}</span>
                    </Link>
                  </li>
                )}
                <li className="toggle_mobile">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="search-bar">
            <input type="text" placeholder="Search..." />
            <button onClick={closeSearch}>
              <i className="fa fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </div>

    <div className={`main_header ${isScrolled ? 'scrolled' : ''}`}>
    <section className="header d-md-block d-none">
        <div className="container">
          <div className="main_inner_header">
            <div className="text-center top_logo_section">
              <Link onClick={handleLinkClick} to="/">
                {" "}
                <img
                  className="mx-auto"
                  src={imgBaseURL() + webAttr?.logo}
                  alt=""
                />
              </Link>
            </div>

            <div className="right_option_bar">
              <ul>
                {authCustomer()?.id ? (
                  <li>
                    <Link to="#">
                      <span>
                        <i class="fa-regular fa-user"></i>
                        {authCustomer()?.name
                          ? authCustomer()?.name?.split(" ")[0]
                          : "User"}
                      </span>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end show">
                      <h6 className="dropdown-header">
                        Welcome {authCustomer()?.name}
                      </h6>
                      <Link className="dropdown-item" to={"/account/orders"}>
                        <i className="fa fa-cart-plus me-1"></i> My Orders
                      </Link>
                      <Link className="dropdown-item" to={"#"}>
                        <i className="fa fa-truck me-1"></i> Track Orders
                      </Link>
                      <Link
                        className="dropdown-item"
                        to={`/account/account-info`}
                      >
                        <i className="fa fa-user me-1"></i> My Account
                      </Link>
                      <div className="dropdown-divider"></div>
                      <Link
                        to={"/"}
                        onClick={() => handleLogout()}
                        className="dropdown-item"
                      >
                        <i class="fa-solid fa-right-from-bracket"></i> Logout
                      </Link>
                    </div>
                  </li>
                ) : (
                  <li>
                    {" "}
                    <Link to="/login">
                      <i class="fa-regular fa-user"></i>Login
                    </Link>{" "}
                  </li>
                )}

                {authCustomer()?.id && (
                  <li>
                    <button
                      className="text-dark planBtn"
                      onClick={() => redirectPage("my-loyalty-points")}
                    >
                      <svg
                        className="me-1"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="22"
                        height="22"
                        x="0"
                        y="0"
                        viewBox="0 0 507.246 507.246"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            d="M457.262 89.821c-2.734-35.285-32.298-63.165-68.271-63.165H68.5c-37.771 0-68.5 30.729-68.5 68.5V412.09c0 37.771 30.729 68.5 68.5 68.5h370.247c37.771 0 68.5-30.729 68.5-68.5V155.757c-.001-31.354-21.184-57.836-49.985-65.936zM68.5 58.656h320.492c17.414 0 32.008 12.261 35.629 28.602H68.5c-13.411 0-25.924 3.889-36.5 10.577v-2.679c0-20.126 16.374-36.5 36.5-36.5zM438.746 448.59H68.5c-20.126 0-36.5-16.374-36.5-36.5V155.757c0-20.126 16.374-36.5 36.5-36.5h370.247c20.126 0 36.5 16.374 36.5 36.5v55.838H373.221c-40.43 0-73.322 32.893-73.322 73.323s32.893 73.323 73.322 73.323h102.025v53.849c0 20.126-16.374 36.5-36.5 36.5zm36.5-122.349H373.221c-22.785 0-41.322-18.537-41.322-41.323s18.537-41.323 41.322-41.323h102.025z"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                            className=""
                          />
                          <circle
                            cx="379.16"
                            cy="286.132"
                            r="16.658"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                            className=""
                          />
                        </g>
                      </svg>
                      ({customerDetails?.loyalty})
                    </button>
                  </li>
                )}

                {authCustomer()?.id && (
                  <li>
                    <button
                      className="text-dark planBtn"
                      onClick={() => redirectPage("wishlist")}
                    >
                      <i class="fa-regular fa-heart"></i>({wishlistData?.length}
                      )
                    </button>
                  </li>
                )}

                <li>
                  <Link>
                    <div className="search-icon" onClick={toggleSearch}>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    {isSearchOpen && (
                      <>
                        <form action="" onSubmit={handleSearch}>
                          <div className="search-bar" ref={searchRef}>
                            <input
                              type="text"
                              placeholder="Search..."
                              value={headerSearchVal}
                              onChange={(e) =>
                                setHeaderSearchVal(e.target.value)
                              }
                            />
                            <button type="button" onClick={closeSearch}>
                              <i className="fa-solid fa-xmark"></i>
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </Link>
                </li>

                {authCustomer()?.id && (
                  <li className="cart_li">
                    <Link
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="512"
                        height="512"
                        x="0"
                        y="0"
                        viewBox="0 0 48 48"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                        fillRule="evenodd"
                        className=""
                      >
                        <g>
                          <path
                            d="M14.519 13.5h-2.657a3.5 3.5 0 0 0-3.491 3.251l-1.714 24a3.5 3.5 0 0 0 3.491 3.749h27.704a3.502 3.502 0 0 0 3.491-3.749l-1.714-24a3.5 3.5 0 0 0-3.491-3.251H33.5V13A9.5 9.5 0 0 0 24 3.5c-5.055 0-9.727 4.026-9.5 9.5l.019.5zm18.981 3V24a1.5 1.5 0 0 1-3 0v-7.5h-13V24a1.5 1.5 0 0 1-3 0s.13-3.505.087-7.5h-2.725a.5.5 0 0 0-.498.464l-1.715 24a.5.5 0 0 0 .499.536h27.704a.502.502 0 0 0 .499-.536l-1.715-24a.5.5 0 0 0-.498-.464zm-3-3V13a6.5 6.5 0 1 0-13 0v.5z"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                          />
                        </g>
                      </svg>
                      ({cartData?.length})
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <header className="d-md-block d-none">
        <nav>
          <ul>
            <li>
              <Link to="/"> Home</Link>
            </li>
            <li>
              <Link to="/about">About Us </Link>
            </li>
            <li className=" dropdown dropdown-hover">
              <Link to="/shop/all" className="dropdown-toggle">
                Shop
              </Link>
              <ul className="dropdown-menu dropdown-menu-md dropdown-menu-center dropdown-menu-list submenu">
                {categories?.map((item, i) => (
                  <li>
                    <Link to={`/shop/${item?.slug}`}>{item?.name}</Link>
                  </li>
                ))}
              </ul>
            </li>
            {/* <li>
              <Link to="/blog">Blog</Link>
            </li> */}
            {/* <li>
              <Link to="/blog">Consult by Doctor</Link>
            </li> */}
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>

      <div
        className="offcanvas offcanvas-start offcanvas-mobile-header"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            {" "}
            <Link onClick={handleLinkClick} to="/">
              {" "}
              <img src={imgBaseURL() + webAttr?.logo} alt="" />
            </Link>
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to="#"
                onClick={() => handleMobileRedirect("/")}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className="nav-link"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="#"
                onClick={() => handleMobileRedirect("/about")}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className="nav-link"
              >
                About Us
              </Link>
            </li>
            <li className="nav-item dropdown dropdown-fullwidth">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                data-bs-toggle="dropdown"
                id="menu-item-blocks-canvas"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Shop
              </Link>
              <div
                className="dropdown-menu mega-menu"
                aria-labelledby="menu-item-blocks-canvas"
              >
                <div className="megamenu-home">
                  <ul className="list-unstyled">
                    {categories?.map((item, i) => (
                      <li>
                      <Link
                        onClick={() => handleMobileRedirect(`/shop/${item?.slug}`)}
                        to={`#`}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close">{item?.name}</Link>
                    </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link
                to="#"
                onClick={() => handleMobileRedirect("/blog")}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className="nav-link"
              >
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className="nav-link"
                to="#"
                onClick={() => handleMobileRedirect("/contact-us")}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;

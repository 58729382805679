import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { APICALL } from '../../../utility/api/api';
import Spinner from '../../../components/admin/Spinner';
import { timeAgo } from './../../../utility/Date';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { formatdedDate, getStatusColor, handleDownloadExcel } from '../../../utility/Utility';

const OrderList = () => {
    const navigate = useNavigate()

    const columns = [
        {
            name: <span className='text-uppercase'>ORDER ID</span>,
            selector: row => <span className='text-uppercase fw-bold'>#{row.id} </span>,
        },
        {
            name: <span className='text-uppercase'>order date</span>,
            selector: row => timeAgo(row.created_at),
        },
        {
            name: <span className='text-uppercase'>payment</span>,
            selector: row => <><span className='text-center'>  {row.payment ? row.payment : "---"}</span></>,
        },
        {
            name: <span className='text-uppercase'>status</span>,
            selector: row => <>
                <span style={{ color: getStatusColor(row?.order_status)?.color, background: getStatusColor(row?.order_status)?.bg }}
                    className={`btn btn-sm text-uppercase`}>
                    {row?.order_status}
                </span>
            </>,
        },
        {
            name: <span className='text-uppercase'>payment Method</span>,
            selector: row => row.payment_method,
        },
        {
            name: <span className='text-uppercase'>Actions</span>,
            cell: row => (
                <div className="dropdown">
                    <button className="dropdown-toggle btn btn-sm btn-icon hide-arrow shadow-none" type="button" id={`dropdownMenuButton${row.id}`} data-bs-toggle="dropdown" aria-expanded="true">
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${row.id}`}>
                        <li className='m-0'><Link to={`/admin/order-details/${row.id}`} className='dropdown-item d-block p-3 w-100'> View Details </Link></li>
                    </ul>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const [listData, setListData] = useState([])
    const [filterData, setFilterData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getListFun()
    }, [])

    const getListFun = async () => {
        setLoading(true)
        const res = await APICALL('/v1/get-orders', 'post', { type: "admin" })
        if (res?.status) {
            setFilterData(res?.data)
            setListData(res?.data)
            setLoading(false)
        } else {
            setListData([])
            setLoading(false)
        }
    }

    const [searchVal, setSearchVal] = useState({
        'drpDownVal': '',
        'searchText': '',
        'fromDate': '',
        'toDate': '',
    })

    const handleFilter = () => {
        console.log(searchVal)
        console.log("list", listData)
        if (searchVal.searchText !== "") {
            const updatedProducts = listData.filter(item =>
                item?.id?.toLowerCase().includes(searchVal.searchText.toLowerCase()) || item?.payment_method?.toLowerCase().includes(searchVal.searchText.toLowerCase()) || item?.order_status?.toLowerCase().includes(searchVal.searchText.toLowerCase())
            );
            setFilterData(updatedProducts)
        }

        if (searchVal.drpDownVal !== "") {
            const updatedProducts = listData.filter(item =>
                item?.payment_method?.toLowerCase().includes(searchVal.drpDownVal.toLowerCase()) || item?.order_status?.toLowerCase().includes(searchVal.drpDownVal.toLowerCase())
            );
            setFilterData(updatedProducts)
        }

        if (searchVal?.fromDate && searchVal?.toDate) {
            const from = new Date(searchVal.fromDate);
            const to = new Date(searchVal.toDate);
            const res = listData.filter(item => {
                const itemDate = new Date(item.created_at);
                return itemDate >= from && itemDate <= to;
            });
            setFilterData(res)
        }
    }

    const filterReset = () => {
        setFilterData(listData)
        setSearchVal({ ...searchVal, 'searchText': '', 'fromDate': '', 'toDate': '', 'drpDownVal': '' })
    }

    const handleDrpDown = (val) => {
        setSearchVal({ ...searchVal, 'drpDownVal': val })
    }

    const changeArrFilterData = (filteredData) => {
        const result = filteredData?.map((sponsor) =>
        ({
            "Order ID": sponsor.id,
            "Order Status": sponsor.order_status,
            "Payment Method": sponsor.payment_method,
            "Total Amount": sponsor.total_amount,
            "Total Products": sponsor.total_products,
            "Total Shipping": sponsor.total_shipping,
            "Discounts": sponsor.discounts,
            "Create Date": formatdedDate(sponsor.created_at),
        })
        )
        return result
    }
    const downloadExcel = (type) => {
        handleDownloadExcel(changeArrFilterData(filterData), "Order List", "order-list")
    };

    return (
        <>
            <div className="content-wrapper">
                {/* Content */}
                <div className="flex-grow-1 container-p-y">
                    {/* <PageHeaderCom pageTitle="Product" link='/admin/add-products' linkBtnName={'add product'} /> */}
                    {/* <PageHeaderCom pageTitle="All Order" /> */}
                    <h4 class="py-3 mb-2"><span class="text-muted fw-light">Aksvedas /</span> Order List</h4>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row g-2 align-items-end mb-3">
                                <div className="col-md-2">
                                    <label htmlFor="">Search</label>
                                    <input type="text" value={searchVal.searchText} onChange={(e) => setSearchVal({ ...searchVal, 'searchText': e.target.value })} class="form-control" placeholder="Search Order" />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="">From Date</label>
                                    <input type="date" class="form-control" value={searchVal.fromDate} placeholder="Search Order" onChange={(e) => setSearchVal({ ...searchVal, 'fromDate': e.target.value })} />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="">To Date</label>
                                    <input type="date" class="form-control" value={searchVal.toDate} placeholder="Search Order" onChange={(e) => setSearchVal({ ...searchVal, 'toDate': e.target.value })} />
                                </div>
                                <div className="col-md-6">


                                    <div className='d-flex justify-content-between order_fliter'>

                                        <div>
                                            <div class="btn-group me-2">
                                                <button type="button" class="btn btn-danger dropdown-toggle text-capitalize" data-bs-toggle="dropdown" aria-expanded="false">{searchVal?.drpDownVal == "" ? "Sort By" : searchVal?.drpDownVal}</button>
                                                <ul class="dropdown-menu text-capitalize">
                                                    <li className='bg-primary text-white h-100'><p className='pb-0 mb-0 fw-bold ps-2'>Order Status</p></li>
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('delivered')}>delivered</Link></li>
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('Pending')}>Pending</Link></li>
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('shipped')}>shipped</Link></li>
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('out for delivery')}>out for delivery</Link></li>
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('out for pickup')}>out for pickup</Link></li>
                                                    {/* <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('cancellation requested')}>cancellation requested</Link></li> */}
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('cancelled')}>cancelled</Link></li>
                                                    {/* <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('lost')}>lost</Link></li> */}

                                                    <li className='bg-primary text-white h-100'><p className='pb-0 mb-0 fw-bold ps-2'>Payment Method</p></li>
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('COD')}>COD</Link></li>
                                                    <li><Link class="dropdown-item" to={'#'} onClick={() => handleDrpDown('UPI')}>UPI</Link></li>
                                                </ul>
                                            </div>
                                            <button class="btn btn-primary" onClick={() => filterReset()}><span>Reset</span></button>
                                            <button class="btn btn-primary ms-2" onClick={() => handleFilter()}><span>Search</span></button>
                                        </div>
                                        <button class="btn btn-success buttons-collection dropdown-toggle btn-label-secondary waves-effect waves-light export_btn" onClick={() => downloadExcel()}><span><i class="ti ti-download me-1"></i>Export</span></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <DataTable
                                className='cs_table_inerr'
                                columns={columns}
                                data={filterData}
                                // dense
                                highlightOnHover
                                selectableRowsHighlight
                                pagination
                            />

                        </div>
                    </div>
                </div>
                <div className="content-backdrop fade" />
            </div>

            <Spinner sppiner={loading} />
        </>
    )
}

export default OrderList
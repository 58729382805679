import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  authCustomer,
  imgBaseURL,
  stringToArray,
  toastifyError,
  toastifySuccess,
  validateEmail,
} from "../../../utility/Utility";
import { useFrontDataContext } from "../../../context/FrontContextProvider";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Draggable from "react-draggable";
import { APICALL } from "../../../utility/api/api";
import emptycart from "../../../assets/img/empty-cart.png";
import { SERVER_ERR, SOMETHING_ERR } from "../../../utility/Constants";
import { cartQntChange } from "../../../utility/api/RepeaterAPI";
import FrontLoader from "../../../components/front/FrontLoader";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const CheckOut = ({ setAddressID }) => {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const navigate = useNavigate();

  const {
    cartData,
    customerDetails,
    getCustomerDetails,
    removeCartItemFun,
    addProductInWishlistFun,
    getCartFun,
    products,
  } = useFrontDataContext();
  const [addressModal, setAddressModal] = useState(false);
  const [updAddress, setUpdAddress] = useState(null)
  const [addressVal, setAddressVal] = useState({
    customer_id: authCustomer()?.id,
    name: customerDetails?.name,
    email: customerDetails?.email,
    address: "",
    state: "",
    city: "",
    phone: customerDetails?.phone,
    type: "",
  });

  useEffect(() => {
    if(!authCustomer()?.token){
      navigate('/login')
    }
  },[])

  useEffect(() => {
    if (updAddress?.id) {
      setAddressVal({
        ...addressVal,
        id: updAddress?.id,
        customer_id: authCustomer()?.id,
        name: updAddress?.name,
        address: updAddress?.address,
        state: updAddress?.state,
        city: updAddress?.city,
        phone: updAddress?.phone,
        zip: updAddress?.zip,
        type: updAddress?.type,
      })
    } else {
      setAddressVal({
        ...addressVal,
        customer_id: authCustomer()?.id,
        name: customerDetails?.name,
        address: "",
        state: "",
        city: "",
        zip: "",
        phone: customerDetails?.phone,
        type: "",
      })
    }
  }, [updAddress])

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    state: "",
    city: "",
    zip: "",
    phone: "",
    address: "",
    type: "Shipping Address",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddressVal((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "state") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        state: value ? "" : "State is required",
      }));
    } else if (name === "city") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        city: value ? "" : "City is required",
      }));
    } else if (name === "email") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: value ? "" : "Email is required",
      }));
      if (value && !validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid email address",
        }));
      }
    } else if (name === "name") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: value ? "" : "Name is required",
      }));
    } else if (name === "zip") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        zip: value ? "" : "Zip Code is required",
      }));
    } else if (name === "phone") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: value ? "" : "Phone is required",
      }));
    } else if (name === "address") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: value ? "" : "Address is required",
      }));
    } else if (name === "type") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        type: value ? "" : "Address Type is required",
      }));
    }
  };

  const handleCreateAddress = async () => {
    try {
      setSubmitLoading(true)
      const requiredFields = ["name", "state", "city", "zip", "address", "phone"];
      const isValid = requiredFields.every((field) => errors[field] === "");

      if (!isValid) {
        setSubmitLoading(false);
        toastifyError("Please correct the errors in the form.");
        return;
      }
      const updatedAddressVal = {};
      Object.keys(addressVal).forEach((key) => {
        if (addressVal[key] !== '') {
          updatedAddressVal[key] = addressVal[key];
        }
      });

      const res = await APICALL(
        "/v1/add-customer-address",
        "post",
        updatedAddressVal
      );
      if (res?.status) {
        toastifySuccess(res.message);
        setSubmitLoading(false)
        getCustomerDetails();
        handleAddressModalClose();
      } else {
        setSubmitLoading(false)
        toastifyError("Please correct the errors in the form.");
      }
    } catch (error) {
      console.log(error);
      setSubmitLoading(false)
      toastifyError(SERVER_ERR);
    }
  };

  const handleRemoveAddress = async (id) => {
    try {
      const res = await APICALL("/v1/remove-customer-address", "post", {
        id: id,
      });
      if (res?.status) {
        toastifySuccess(res.message);
        getCustomerDetails();
      } else {
        toastifyError(SOMETHING_ERR);
      }
    } catch (error) {
      console.log(error);
      toastifyError(SERVER_ERR);
    }
  };

  const [cartList, setCartList] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [])
  useEffect(() => {
    if (cartData.length > 0) {
      setCartList(cartData);
    }
  }, [cartData]);

  const handleQntChange = (qntType, itemId) => {
    const updatedCartItems = cartList.map((item) => {
      if (item.id === itemId) {
        let newQuantity;
        if (qntType === "minus") {
          newQuantity = Math.max(1, parseInt(item.qnt) - 1);
        } else {
          newQuantity = Math.min(5, parseInt(item.qnt) + 1);
        }
        if (newQuantity <= 5) {
          const param = { product_id: item.product_id, qnt: newQuantity };
          cartQntChange(param);
        }
        getCartFun()
        return { ...item, qnt: newQuantity };
      }
      return item;
    });
    setCartList(updatedCartItems);
  };

  const handleAddressModalClose = () => {
    setAddressModal(false);
    setAddressVal({
      ...addressVal,
      address: "",
      city: "",
      phone: customerDetails?.phone,
      name: customerDetails?.name,
      email: customerDetails?.email,
      id: '',
      state: "",
      zip: "",
    });
    setErrors({
      ...errors,
      name: "",
      email: "",
      address: "",
      city: "",
      phone: "",
      state: "",
      zip: "",
    });
  };

  const commoProducts = (product_ids) => {
    if (product_ids) {
      const idsss = stringToArray(product_ids);
      const filteredProducts = products.filter((product) =>
        idsss.includes(product.id)
      );
      return filteredProducts;
    }
  };

  return (
    <>
      {loading && <FrontLoader />}

      <div className="col-xl-8">
        <div class="col-lg-12">
          <div class="d-flex align-items-center justify-content-end">
            {cartData?.length > 0 && (
              <div class="flex-shrink-0 mb-3">
                <button
                  type="button"
                  className="btn-normals w-xs"
                  onClick={() => removeCartItemFun(`C_${authCustomer()?.id}`)}
                >
                  Clear Cart
                </button>
              </div>
            )}
          </div>
          {cartData?.length > 0 &&
            cartList?.map((item, i) => (
              <div class="card product mb-3">
                <div class="card-body">
                  <div class="row gy-3 algin-items-baseline">
                    <div class="col-sm-auto">
                      <div class="avatar-lg">
                        <div class="avatar-title rounded py-3">
                          <img
                            src={imgBaseURL() + item?.cover}
                            alt=""
                            class="avatar-md"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm">
                      <a href="#!">
                        <h5 class="main_check lh-base mb-1">{item?.name}</h5>
                      </a>
                      <ul class=" list-inline text-muted fs-13 mb-0">
                        <span> SKU : {item?.sku}</span>
                      </ul>
                      {commoProducts(item.product_items_id)?.length > 0 && (
                        <ol className="bundle-pro-check mt-2">
                          <>
                            {commoProducts(item.product_items_id)?.map(
                              (bundle_pro, i) => (
                                <li>

                                  <span>
                                    {bundle_pro?.name}{" "}

                                  </span>
                                </li>
                              )
                            )}
                          </>
                        </ol>
                      )}
                      {
                        item?.subscription_id && 
                        <p className="subscription_plan_label"> Subscription Plan : <span className="checkmark"></span>{item?.subscription_days} Days ₹{item?.subscription_sale_price} @{item?.subscription_discount}% off</p>
                      }
                      <div className="product-button mb-0 mt-2">
                        <div className="input-step">
                          <button
                            type="button"
                            className="minus"
                            onClick={() => handleQntChange("minus", item.id)}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            className="product-quantity1"
                            value={item?.qnt}
                            readOnly
                          />
                          <button
                            type="button"
                            className="plus"
                            onClick={() => handleQntChange("plus", item.id)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-auto">
                      <div class="text-lg-end">
                        <p class="text-muted mb-1 fs-12">Item Price:</p>
                        <h5 class="fs-16">
                          ₹<span class="product-price">{item?.sale_price}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer pt-2">
                  <div class="row align-items-center gy-3">
                    <div class="col-sm">
                      <div class="d-flex flex-wrap my-n1">
                        <div className="text-danger">
                          <Link
                            className="d-block colorRedcolor"
                            to={"#"}
                            onClick={() => removeCartItemFun(item?.id)}
                          >
                            <i class="fa fa-trash  me-1"></i> Remove
                          </Link>
                        </div>
                        <div>
                          <Link
                            className="d-block"
                            to={"#"}
                            onClick={() =>
                              addProductInWishlistFun(item?.product_id)
                            }
                          >
                            <i class="fa fa-heart  me-1"></i> Add Wishlist
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-auto">
                      <div class="d-flex align-items-center gap-2 text-muted">
                        <div>Total :</div>
                        <h5 class="fs-14 mb-0">
                          ₹
                          <span class="product-line-price">
                            {item?.sale_price * parseInt(item.qnt)}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {cartData?.length > 0 && (
          <div className="mt-4">
            <div className="d-flex align-items-center mb-4">
              <div className="flex-grow-1">
                <h5 className="mb-0">Address</h5>
              </div>
              {customerDetails?.address?.length < 5 && (
                <div className="flex-shrink-0">
                  <Link
                    href="#"
                    onClick={() => setAddressModal(true)}
                    className="add_address btn-normals fs-14"
                  >
                    Add Address
                  </Link>
                </div>
              )}
            </div>
            <div className="row gy-3">
              {customerDetails?.address?.length > 0 ? (
                <>
                  {customerDetails?.address?.map((item, i) => (
                    <div className="col-lg-6 col-12">
                      <div className="form-check card-radio">
                        {i === 0 && setAddressID(item?.id)}
                        <input
                          id={`radioAddress${i}`}
                          name="shippingAddress"
                          type="radio"
                          value={item?.id}
                          onChange={() => setAddressID(item?.id)}
                          className="form-check-input"
                          defaultChecked={i === 0}
                        />
                        <label
                          className="form-check-label"
                          for={`radioAddress${i}`}
                        >
                          <h6 className="mb-2">{"Address"} {i + 1} - {item?.name}</h6>
                          <p className="mb-0">{item?.address}</p>
                          <p className="mb-0">{item?.phone}</p>
                        </label>
                      </div>
                      <div className="d-flex flex-wrap p-2 py-1 bg-grays rounded-bottom border mt-n1">
                        <div>
                          <Link to={"#"} onClick={() => { setUpdAddress(item); setAddressModal(true) }}>
                            <i className="fa fa-edit"></i> Edit
                          </Link>
                        </div>
                        <div className="ms-3">
                          <Link
                            to={"#"}
                            className="colorRedcolor"
                            onClick={() => handleRemoveAddress(item?.id)}
                          >
                            <i className="fa fa-trash"></i> Remove
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="px-3">
                    <Alert severity="error">
                      Please add shipping address after you can continue.
                    </Alert>
                  </div>
                  <div className="flex-shrink-0">
                    <Link
                      href="#"
                      onClick={() => setAddressModal(true)}
                      className="add_address btn-normals fs-14"
                    >
                      Add Address
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {cartData?.length < 1 && (
        <div class="product-item-inner">
          <img src={emptycart} alt="" />
          <h4>There are no product added on your cart.</h4>
        </div>
      )}

      <Dialog
        open={addressModal}
        onClose={() => setAddressModal(false)}
        PaperComponent={PaperComponent}

      >
        <div className="main_header">
          <DialogTitle className="top_pop_header">
            Address

          </DialogTitle>
          <div className="close_btn_pop"><button onClick={handleAddressModalClose} className="border-0 p-0 text-black"><i class="fa-solid fa-xmark"></i></button></div>
        </div>
        <DialogContent className="pt-0">
          <div className="row mt-2 g-3">
            <div className="col-md-12">
              <input
                type="text"
                name="name"
                value={addressVal.name}
                onChange={handleChange}
                placeholder="Name"
                className="form-control"
              />
              <span className="errMsg">{errors.name}</span>
            </div>
            <div className="col-md-12">
              <input
                type="text"
                name="email"
                value={addressVal.email}
                onChange={handleChange}
                placeholder="Email"
                className="form-control"
                disabled={customerDetails.email ? true : false}
              />
              <span className="errMsg">{errors.email}</span>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="state"
                value={addressVal.state}
                onChange={handleChange}
                placeholder="State"
                className="form-control"
              />
              <span className="errMsg">{errors.state}</span>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="city"
                value={addressVal.city}
                onChange={handleChange}
                placeholder="City"
                className="form-control"
              />
              <span className="errMsg">{errors.city}</span>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="zip"
                value={addressVal.zip}
                onChange={handleChange}
                placeholder="Zip Code"
                className="form-control"
              />
              <span className="errMsg">{errors.zip}</span>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="phone"
                value={addressVal.phone}
                onChange={handleChange}
                placeholder="Phone"
                className="form-control"
              />
              <span className="errMsg">{errors.phone}</span>
            </div>
            <div className="col-md-12">
              <textarea
                type="text"
                name="address"
                value={addressVal.address}
                onChange={handleChange}
                className="form-control"
                placeholder="Address"
              >
                {" "}
              </textarea>
              <span className="errMsg">{errors.address}</span>
            </div>
            {/* <div className="col-12">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Address Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="type"
                  value={addressVal.type}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Billing Address"
                    control={<Radio />}
                    label="Billing Address"
                  />
                  <FormControlLabel
                    value="Shipping Address"
                    control={<Radio />}
                    label="Shipping Address"
                  />
                </RadioGroup>
              </FormControl>
            </div> */}
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center">
          <div className="row w-100">
            <div className="col-md-12">
              <div className="pop_btn text-center w-100">
                {
                  !submitLoading ?
                    <button type="button" className="btn-2 w-100 mb-3" onClick={handleCreateAddress}>
                      Add <i class="fa-solid fa-plus mx-1"></i>
                    </button>
                    :
                    <button type="button" className="btn-2 w-100 mb-3" >
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                    </button>
                }
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default CheckOut;

import { Breadcrumbs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import siderbg from "../../../assets/img/bg-about.png";

import OrderSummary from '../../../components/front/OrderSummary';
import Cart from '../cart/Cart';
import CheckOut from '../checkout/CheckOut';
import Payment from '../payment/Payment';
import { useFrontDataContext } from '../../../context/FrontContextProvider';
import { toastifyError } from '../../../utility/Utility';

const Index = () => {
    const { cartData, customerDetails,shippingDetails } = useFrontDataContext()

    const navigate = useNavigate()
    const path = useLocation().pathname
    const pathWithoutSlash = path.substring(1);
    const [addressID, setAddressID] = useState(null)

    const [offerCouponObj, setOfferCouponObj] = useState(null)
    const [loyaltyDiscount, setLoyaltyDiscount] = useState(0)



    const getSubTotalFunc = () => {
        const subtotal = cartData.reduce((total, item) => total + parseFloat(item?.sale_price) * parseInt(item.qnt), 0);
        return subtotal;
    }

    const getSubTotalAmnt = () => {
        const subTotalAmount = getSubTotalFunc()
        const savingAmount = parseInt(offerCouponObj?.saving ?? 0)
        const shippingCharge = parseInt(shippingDetails?.shipping_charge ?? 0);
        const amountAfterSaving = subTotalAmount - savingAmount;
        const total = amountAfterSaving < parseInt(shippingDetails?.total_amnt) ? amountAfterSaving + shippingCharge : amountAfterSaving;
        return total - loyaltyDiscount;
    }

    const continueNow = () => {
        const data = {
            addressID,
            totalAmount: getSubTotalAmnt(),
            shippingCharge: getSubTotalFunc() < parseInt(shippingDetails?.total_amnt) ? parseInt(shippingDetails?.shipping_charge ?? 0) : 0,
            products: cartData,
            discounts: offerCouponObj?.saving,
            loyaltyDiscount: loyaltyDiscount,
            coupon: offerCouponObj?.coupon,
            totalProducts: cartData?.length,
        }
        console.log("data",data)
        if (pathWithoutSlash === "cart") {
            navigate('/checkout')
        } else {
            if (customerDetails?.address?.length > 0) {
                navigate('/payment', { state: data })
            } else {
                toastifyError("Please add shipping address after you can continue.")
            }
        }
    }

    return (
        <>

            <div
                className="innabout-section"
                style={{ backgroundImage: `url(${siderbg})` }}
            >
                <div className="container">
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        className="breacrumb-custom py-md-3 py-2"
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        <Link underline="hover" to="/"> Home</Link>
                        <Typography className='text-capitalize'>{pathWithoutSlash}</Typography>
                    </Breadcrumbs>
                    <h1 className='text-capitalize'>{pathWithoutSlash}</h1>
                </div>
            </div>

            <section className="cart-section">
                <div className="container">
                    <div className="row">
                        {
                            pathWithoutSlash === 'cart' ?
                                <Cart />
                                :
                                pathWithoutSlash === 'checkout' ?
                                    <CheckOut setAddressID={setAddressID} />
                                    :
                                    pathWithoutSlash === 'payment' ?
                                        <Payment />
                                        :
                                        <></>
                        }
                        {
                            cartData?.length > 0 &&
                            <div className="col-lg-4  rightdiv-tableorder mt-lg-0 mt-3">
                                <div className="sticky-side-div">
                                    <OrderSummary subTotal={getSubTotalFunc()} setOfferCouponObj={setOfferCouponObj} setLoyaltyDiscount={setLoyaltyDiscount} loyaltyDiscount={loyaltyDiscount}  />
                                    <div className="product-button">
                                        <div className="doble_btn-pro">
                                            {
                                                pathWithoutSlash === 'payment' ? 
                                                <Link to="/checkout" className="btn-2 buy-btn">Back{" "}</Link>
                                                :
                                                <Link to="/shop/all" className="btn-2 buy-btn">Continue Shoping{" "}</Link>
                                            }
                                            <button type="button" className="btn-2" onClick={() => continueNow()}>
                                                {"Continue Payment"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </section>

        </>
    )
}

export default Index
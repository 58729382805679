import React, { useEffect, useState } from 'react'
import { APICALL } from '../../utility/api/api'
import { authCustomer } from '../../utility/Utility'
import { useFrontDataContext } from '../../context/FrontContextProvider'

const OrderSummary = (props) => {
    const { shippingDetails, cartData, customerDetails } = useFrontDataContext()

    const [discountOnCategory, setDiscountOnCategory] = useState([])
    const { subTotal, setOfferCouponObj, loyaltyDiscount, setLoyaltyDiscount } = props
    const [coupon, setCoupon] = useState('')
    const [response, setResponse] = useState({
        error: null,
        msg: null,
        coupon: null,
        allow: '',
        saving: 0,
    })

    const applyCoupon = async () => {
        try {
            const params = { coupon, customer_id: authCustomer()?.id }
            const res = await APICALL('/use-coupon', 'post', params)
            if (res?.status) {
                if (res?.data?.allow === 'all') {
                    const saving = calculateSavings(res?.data, subTotal)
                    setOfferCouponObj({ saving: saving * parseInt(cartData?.length), coupon: coupon, allow: res?.data?.allow })
                    setResponse({ ...response, 'error': res?.status, 'msg': res?.message, 'coupon': res?.data?.coupon_code, saving: saving, allow: res?.data?.allow })
                }
                if (res?.data?.allow !== 'all') {
                    const filteredProducts = cartData.filter(product => {
                        const categoryIds = product.category_id.split(',').map(id => parseInt(id.trim()));
                        return categoryIds.includes(parseInt(res?.data?.allow));
                    });
                    const categoryTotal = filteredProducts.reduce((total, item) => total + parseFloat(item?.sale_price) * parseInt(item.qnt), 0);
                    const saving = calculateSavings(res?.data, categoryTotal)
                    setOfferCouponObj({ saving: saving * parseInt(filteredProducts?.length), coupon: coupon, allow: res?.data?.allow })
                    setResponse({ ...response, 'error': res?.status, 'msg': res?.message, 'coupon': res?.data?.coupon_code, saving: saving, allow: res?.data?.allow })
                    setDiscountOnCategory(filteredProducts);
                }
            } else {
                setResponse({ ...response, 'error': res?.status, 'msg': res?.message })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getFreeDeliveryText = () => {
        const subTotalAmount = parseInt(subTotal);
        const savingAmount = savingAmnt()
        const amountAfterSaving = subTotalAmount - savingAmount;
        const shippingCharge = parseInt(shippingDetails?.shipping_charge ?? 0);

        if (amountAfterSaving < parseInt(shippingDetails?.total_amnt)) {
            return <> <span>₹{shippingCharge}</span> </>
        } else {
            return <> <i className='text-success text-uppercase'><del className="high_price fw-bold">₹{shippingCharge}</del> Free delivery</i> </>
        }
    }

    const getSubTotalAmnt = () => {
        const subTotalAmount = parseInt(subTotal);
        const savingAmount = savingAmnt()
        const shippingCharge = parseInt(shippingDetails?.shipping_charge ?? 0);
        const amountAfterSaving = subTotalAmount - savingAmount;
        const total = amountAfterSaving < parseInt(shippingDetails?.total_amnt) ? amountAfterSaving + shippingCharge : amountAfterSaving;
        return total - loyaltyDiscount;
    }

    const calculateSavings = (coupon, totalAmount) => {
        let savings = 0;
        if (coupon.coupon_type === 'fixed') {
            savings = coupon.offer;
        } else if (coupon.coupon_type === 'percentage') {
            savings = (coupon.offer / 100) * totalAmount;
        }
        return savings;
    };

    useEffect(() => {
        if (cartData) {
            setOfferCouponObj({ saving: savingAmnt(), coupon: coupon, allow: response?.allow })
        }
    }, [cartData?.length])

    const savingAmnt = () => {
        const savingAmount = response.allow === 'all' ? parseInt(response.saving ?? 0) * parseInt(cartData?.length) : parseInt(response.saving ?? 0) * parseInt(discountOnCategory?.length);
        return savingAmount
    }

    const [total50, setTotal50] = useState()
    const submitLoyaltyDiscount = () => {
        const loyaltyAmnt = customerDetails?.loyalty
        // const loyaltyAmnt = 200
        if(loyaltyDiscount === 0){
            const total = getSubTotalAmnt() / 2
            setTotal50(total)
            if (total >= loyaltyAmnt) {
                setLoyaltyDiscount(loyaltyAmnt)
            } else {
                setLoyaltyDiscount(total)
            }
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="text-center">
                        <h6 className="mb-3 fs-14">
                            Have a <span className="fw-semibold">promo</span> code ?
                        </h6>
                    </div>
                    <div className="hstack gap-3 px-3 mx-n3">
                        <input
                            className="form-control me-auto"
                            type="text"
                            placeholder="Enter coupon code"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                            aria-label="Add Promo Code here..."
                        />
                        <button type="button" className="btn-normals w-xs" onClick={() => applyCoupon()}>Apply</button>
                    </div>
                    <span className={response.error ? 'text-success' : 'text-danger'}>{response?.msg}</span>

                    <div className='mt-3'>
                        <span>Use AksCoins <button type="button" className="btn-sm btn-normals w-xs" onClick={() => submitLoyaltyDiscount()}>Use ₹{customerDetails?.loyalty}</button></span>
                    </div>
                </div>
            </div>
            <div className="card overflow-hidden mt-4">
                <div className="card-header border-bottom-dashed">
                    <h5 className="card-title mb-0 fs-15">Order Summary</h5>
                </div>
                <div className="card-body pt-4">
                    <div className="table-responsive table-card">
                        <table className="table table-borderless mb-0 fs-15">
                            <tbody>
                                <tr>
                                    <td>Sub Total :</td>
                                    <td className="text-end cart-subtotal">₹{subTotal}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Discount{" "}
                                        <span className="text-muted">({response?.coupon})</span>:
                                    </td>
                                    <td className="text-end cart-discount">₹{savingAmnt()}</td>
                                </tr>
                                <tr>
                                    <td>AksCoins :
                                        {
                                            (customerDetails?.loyalty > total50 && loyaltyDiscount != 0) &&
                                            <>
                                                <span className='text-success'>You can use ₹{loyaltyDiscount} Akscoins for this order</span>
                                            </>
                                        }
                                    </td>
                                    <td className="text-end cart-tax"> ₹{loyaltyDiscount}</td>
                                </tr>
                                <tr>
                                    <td>Shipping Charge :</td>
                                    <td className="text-end cart-shipping">{getFreeDeliveryText()}</td>
                                </tr>
                                <tr className=" bg-grays">
                                    <th><strong>Total :</strong></th>
                                    <td className="text-end">
                                        <span className="fw-semibold cart-total">
                                            ₹{getSubTotalAmnt()}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderSummary
import React, { useState } from 'react';
import { authCustomer } from '../../utility/Utility';
import { addToCartRepeater } from '../../utility/api/RepeaterAPI';
import { useNavigate } from 'react-router';
import { useFrontDataContext } from '../../context/FrontContextProvider';

const ProductItemButton = ({ row }) => {
  const { getWishlistFun, getCartFun } = useFrontDataContext();
  const [cartLoading, setCartLoading] = useState(false)
  const navigate = useNavigate()
  const addToCartFun = async (type, item) => {
    const param = { product_id: item.id, qnt: 1 };
    if (authCustomer()?.id) {
      if (item.quantity > 0 && item.product_type !== 4) {
        setCartLoading(true)
        await addToCartRepeater(param, getWishlistFun, getCartFun, 0, 1);
        if (type == "buy") {
          navigate("/checkout");
        }
        setCartLoading(false)
      } else {
        navigate(`/product-detail/${item.slug}`);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      {
        row?.quantity > 0 ?
          <>
            <div className="doble_btn global_btn d-flex mt-3">
              <button className="btn-2 w-100" onClick={() => addToCartFun("buy", row)}>
                Buy Now <i className="fa-solid fa-arrow-right"></i>
              </button>
              {
                cartLoading ?
                  <button class="btn-2 w-100" type="button">
                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span role="status"> Loading...</span>
                  </button>
                  :
                  <button className="btn-2 w-100" onClick={() => addToCartFun("cart", row)}>
                    <i className="me-1 fa-solid fa-cart-plus"></i>Add To cart
                  </button>
              }

            </div>
          </>
          :
          <div className="doble_btn global_btn d-flex mt-3">
            <button className="btn-2 w-100" onClick={() => addToCartFun("buy", row)}>
              Buy Now <i className="fa-solid fa-arrow-right"></i>
            </button>

            <button className="btn-2 w-100" onClick={() => addToCartFun("cart", row)} >
              <i className="me-1 fa-solid fa-cart-plus"></i>Add To cart
            </button>
          </div>
      }

    </>
  )
}

export default ProductItemButton